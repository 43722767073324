import { Header } from '@/utils/datatable/datatableInterface';
import DatatableIntetrface from '@/utils/datatable/datatableInterface'
import Datatable  from '@/utils/datatable/datatable'
const headers:Header[] = [
  {value:'Id', text: 'Id' , isPrice : false , isTotal:false , total:0},
  {value:'Name', text: 'Name' , isPrice : false , isTotal:false , total:0},
  {value:'NameAr', text: 'NameAr' , isPrice : false , isTotal:false , total:0},
  {value:'Phone', text: 'Phone' , isPrice : false , isTotal:false , total:0},
  {value:'Logo', text: 'logo' , isPrice : false , isTotal:false , total:0},
  {value:'DayOffs', text: 'DayOffs' , isPrice : false , isTotal:false , total:0},
  {value:'OpenTime', text: 'OpenTime' , isPrice : false , isTotal:false , total:0},
  {value:'CloseTime', text: 'CloseTime' , isPrice : false , isTotal:false , total:0},
  {value:'Actions', text: 'Actions' , isPrice : false , isTotal:false , total:0},
]




const url = "centers"

const params:DatatableIntetrface = {
    title : "centers",
    headers ,
    description:"centers_desc",
    searchable:true,
    url,
    hasFooter:false,
    hasEdit:true,
    hasCreate:true
}
const datatable = new Datatable(params)
export default datatable